import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { useAppConfig, useStorage } from '@ngb-frontend/shared/context'
import { BookingStatus } from '@ngb-frontend/shared/types'

import { servicesByServiceCode } from '../service/servicesByServiceCode'

import type {
  Booking,
  BookingServiceCode,
  QueryCountryCode,
  VehicleIdentifier,
} from '@ngb-frontend/shared/types'

export const filterHistoryBySrvCode = (
  serviceCode: BookingServiceCode,
  history?: Booking[],
) => {
  return history?.filter(
    (his) =>
      his.service?.items?.some((i) =>
        servicesByServiceCode[serviceCode].includes(i.code),
      ) &&
      (his.status === BookingStatus.Confirmed ||
        his.status === BookingStatus.Unconfirmed),
  )
}

export const useBookingRequestHistory = (
  vehicleIdentifier?: VehicleIdentifier,
  serviceCode?: BookingServiceCode,
  countryCode?: QueryCountryCode,
) => {
  const config = useAppConfig()
  const { getLocalStorage, setLocalStorage } = useStorage()

  const [hasBeenChecked, setHasBeenChecked] = useState(
    !!getLocalStorage('bookingHistoryChecked'),
  )
  const res = useSWR<Booking[]>(
    vehicleIdentifier && countryCode
      ? [
          config.apiRoutes.fetchBookingHistory,
          { vehicleIdentifier: vehicleIdentifier, countryCode },
        ]
      : null,
  )

  const markAsChecked = useCallback(() => {
    setLocalStorage('bookingHistoryChecked', true)
    setHasBeenChecked(true)
  }, [setLocalStorage])

  const filteredData = useMemo(() => {
    return serviceCode
      ? filterHistoryBySrvCode(serviceCode, res.data)
      : res.data
  }, [res.data, serviceCode])

  const checkedFromLS = !!getLocalStorage('bookingHistoryChecked')
  useEffect(() => {
    setHasBeenChecked(checkedFromLS)
  }, [checkedFromLS, getLocalStorage])

  const hasData = !!filteredData?.length

  return {
    ...res,
    markAsChecked,
    hasBeenChecked,
    hasData,
    data: filteredData,
  }
}
